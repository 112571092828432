// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_sub = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onLeave',
  // duration: 800,
  offset: 400,
})
  // .addIndicators()
  .addTo(controller);

scene_sub.on('enter', () => {
  $(".l-sub").addClass("-current");
});
scene_sub.on('leave', ()=>{
  $(".l-sub").removeClass("-current");
});
