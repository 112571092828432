// import Simplemodal from '../../plugins/simplemodal.js';
//
// const fullScreenMenu = new Simplemodal({
//   trigger : "data-fullScreenMenu-trigger",
//   clickOutSideClose : true
// })

import fullScreenMenu from '../../plugins/fullscreenmenu.js';

fullScreenMenu.init();
