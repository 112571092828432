// var pathname = function () {
//   return location.pathname == "/index.php" ? "/" : location.pathname;
// };
//
// $(".megamenu-main-link").each(function () {
//   var $href = $(this).attr("href");
//   if (location.href.match($href) && $href != "/") {
//     $(this).addClass("is-current");
//   }
// });

$(function() {
  //グローバルナビのカレント設定
  // ページurlを"/"で区切った時の文字列を取得
  var currentCheckUrl = location.pathname.split("/")[1];
  var targetNav = $(".megamenu-wrapper");
  targetNav.each(function(){
    var href = $(this).find("a:first").attr("href").split("/")[1];

    if( href == currentCheckUrl ) {
 	 $(this).addClass("is-current");
    };
  });

  var currentCheckUrl = location.pathname.split("/")[1];
  var targetNav = $(".l-nav-list__item");
  targetNav.each(function(){
    var href = $(this).find("a:first").attr("href").split("/")[1];

    if( href == currentCheckUrl ) {
 	 $(this).addClass("is-current");
    };
  });
});
